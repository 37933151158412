<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider name="Firma Adı" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Firma Adı">
                <b-form-input
                  size="lg"
                  class="rounded-0 mb-3"
                  ref="firma_adi"
                  v-model="form.firma_adi"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Yetkili Ad Soyad">
              <b-form-input size="lg" class="rounded-0 mb-3" ref="sifre" v-model="form.yetkili_ad_soyad" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Fatura Başlığı">
              <b-form-input size="lg" class="rounded-0 mb-3" ref="sifre" v-model="form.firma_fatura_baslik" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Adres">
              <b-form-textarea id="adres" v-model="form.adres" class="rounded-0 mb-3" rows="2" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Telefon">
              <b-form-input size="lg" class="rounded-0" ref="sifre" v-model="form.telefon" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="E Mail">
              <b-form-input size="lg" class="rounded-0" ref="sifre" v-model="form.e_mail" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Web Site">
              <b-form-input size="lg" class="rounded-0" v-model="form.web_site" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                {{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}
              </b-button>
              <b-button squared size="lg" variant="danger">
                <i class="fad fa-trash"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.form = ref({
      k_no: null,
      firma_adi: null,
      yetkili_ad_soyad: null,
      firma_fatura_baslik: null,
      adres: null,
      telefon: null,
      e_mail: null,
      web_site: null,
    });

    const handlerConfig = async () => {
      context.emit('show', true);
      await store.dispatch('firmaBilgiGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerConfig();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('firmaBilgiEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          context.emit('show', false);
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
